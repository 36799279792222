<template>
        <div class="d-flex flex-column align-items-center mx-auto">
            <div class="logo-container my-3">
            <img src="https://endemolcxfprod-16905.kxcdn.com/public-assets/logo-los-50" alt="" class="los-50-logo mb-5">
            </div>
            <div class="loader-wrapper">
            <div class="loader-points">
                <div class="loader-point">
                <div class="point point-1"></div>
                <div class="point-shadow point-shadow-1"></div>
                </div>
                <div class="loader-point">
                <div class="point point-2"></div>
                <div class="point-shadow point-shadow-2"></div>
                </div>
                <div class="loader-point">
                <div class="point point-3"></div>
                <div class="point-shadow point-shadow-3"></div>
                </div>
            </div>
            <p class="loader-text">{{ text ? text : 'Cargando' }}</p>
        </div>
        </div>
  </template>
  <script>
  
  

  export default {
    name: "App",
    props: {
      text: String,
    },
  };
  </script>

  <style lang="scss">
    * {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: helvetica;
}
body {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.loader {

  &-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &-points{
    display: flex;
    justify-content: space-between;
    width: 100px;
    margin-bottom: 10px;
  }
  &-text{
    text-transform: capitalize;
    text-align: center;
    font-family: helvetica;
    color:white;
  }
}
.point {  
  width: 18px;
  height: 18px;
  background-color: white;
  border-radius: 50%;
  transform: translateY(-30px);
  
  &-shadow {
    width: 100%;
    height: 30%;
    background-color: gray;
    border-radius: 50%;
  }
}

@for $i from 1 through 3 {
  
  .point-#{$i} {
    animation-name: point;
    animation-duration: 0.5s;
    animation-delay: $i/5 + s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in;    
  }
  .point-shadow-#{$i} {
    animation-name: shadow;
    animation-duration: 0.5s;
    animation-delay: $i/5 + s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in;
    background-color: var(--main-color) !important;
  }
}
@keyframes point {
  0% {
    transform: translateY(-30px) scaleX(1) scaleY(1);
  }
  100% {
    transform: translateY(0px) scaleX(1.1) scaleY(0.9);
  }
}
@keyframes shadow {
  0%{
    transform: scaleX(0.2);
    background-color: gray;
  }
  100%{
    transform: scaleX(0.9);
    background-color: #3c3c3c;
  }
}
</style>

